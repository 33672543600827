import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar';
import Players from './Players'; // Importez le composant Players
import Home from './components/Home';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
        <Sidebar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/players" element={<Players />} />
            {/* Ajoutez d'autres routes ici si nécessaire */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
